import React, { useState } from 'react';
import { BaseKey, IResourceComponentsProps } from '@refinedev/core';
import {
  useTable,
  List,
  ImageField,
  DateField,
  ShowButton,
  BooleanField,
} from '@refinedev/antd';
import { Button, Flex, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { BaseRecord } from '@pankod/refine-core';
import { EditOutlined } from '@ant-design/icons';
import { FootballFestMatchEditModal } from './components/FootballFestMatchEditModal';
import { FootballFestMatchFilter } from './components/FootballFestMatchFilter';

export const FootballFestMatchList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: false,
    resource: 'football-fest/match',
    sorters: {
      initial: [
        {
          field: 'endTime',
          order: 'asc',
        },
      ],
    },
    filters: {
      initial: [
        {
          field: 'endTimeAfter',
          operator: 'eq',
          value: dayjs().startOf('day').subtract(1, 'day'),
        },
      ],
    },
    onSearch: (params: any) => {
      return Object.entries(params).map(([key, value]) => ({
        field: key,
        operator: 'eq',
        value,
      }));
    },
  });

  const [editModalId, setEditModalId] = useState<BaseKey | null | undefined>(
    null,
  );

  return (
    <Flex vertical gap={'16px'}>
      <FootballFestMatchFilter formProps={searchFormProps} />
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="Id" />
          <Table.Column dataIndex="teamA" title="Team A" />
          <Table.Column
            dataIndex={['teamAIconUrl']}
            title="Team A Icon"
            render={(value: any) => (
              <ImageField style={{ maxWidth: '100px' }} value={value} />
            )}
          />
          <Table.Column dataIndex="teamB" title="Team B" />
          <Table.Column
            dataIndex={['teamBIconUrl']}
            title="Team B Icon"
            render={(value: any) => (
              <ImageField style={{ maxWidth: '100px' }} value={value} />
            )}
          />
          <Table.Column dataIndex="location" title="Location" />
          <Table.Column
            dataIndex={['startTime']}
            title={`Start time (${dayjs().format('Z')})`}
            render={(value: any) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            dataIndex={['endTime']}
            title={`End time (${dayjs().format('Z')})`}
            render={(value: any) => <DateField value={value} format="LLL" />}
            sorter={(a: any, b: any) => a.endTime - b.endTime}
            defaultSortOrder={'ascend'}
          />
          <Table.Column
            dataIndex={['displayPriority']}
            title={`Display priority`}
          />
          <Table.Column
            title={`Scores`}
            align="center"
            render={(value: any) =>
              dayjs().isBefore(value.startTime) ? (
                'Not yet started'
              ) : (
                <Flex vertical gap={'small'}>
                  <span>
                    Score: {value.teamAScore} - {value.teamBScore}
                  </span>
                  <span>
                    Penalty: {value.teamAPenalty} - {value.teamBPenalty}
                  </span>
                </Flex>
              )
            }
          />
          <Table.Column dataIndex={['category', 'name']} title="Category" />
          <Table.Column
            dataIndex={['isActive']}
            title="Is active"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => {
                    setEditModalId(record.id);
                  }}
                />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
        {typeof editModalId !== 'undefined' && editModalId !== null && (
          <FootballFestMatchEditModal
            title={`Edit match`}
            open
            onClose={() => {
              setEditModalId(null);
            }}
            recordId={editModalId}
          />
        )}
      </List>
    </Flex>
  );
};
