import { SearchOutlined } from '@ant-design/icons';
import { useSelect } from '@refinedev/antd';
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  FormProps,
  Input,
  Select,
} from 'antd';
import dayjs from 'dayjs';

export const FootballFestMatchFilter = ({
  formProps,
}: {
  formProps: FormProps;
}) => {
  const { selectProps: categoriesSelectProps } = useSelect({
    resource: 'football-fest/category',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value,
      },
    ],
  });

  return (
    <Collapse style={{ marginBottom: 16 }}>
      <Collapse.Panel header="Filter" key="1">
        <Form
          layout="horizontal"
          style={{ display: 'flex', gap: 16 }}
          {...formProps}
        >
          <Form.Item label="Category" name="category">
            <Select
              {...categoriesSelectProps}
              allowClear
              placeholder="Select category"
            />
          </Form.Item>

          <Form.Item label="Team A" name="teamA">
            <Input placeholder="Team name" prefix={<SearchOutlined />} />
          </Form.Item>

          <Form.Item label="Team B" name="teamB">
            <Input placeholder="Team name" prefix={<SearchOutlined />} />
          </Form.Item>

          <Form.Item
            label="Start before"
            name="startTimeBefore"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : undefined,
            })}
          >
            <DatePicker showTime />
          </Form.Item>

          <Form.Item
            label="Start after"
            name="startTimeAfter"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : undefined,
            })}
          >
            <DatePicker showTime />
          </Form.Item>

          <Form.Item
            label="End before"
            name="endTimeBefore"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : undefined,
            })}
          >
            <DatePicker showTime />
          </Form.Item>

          <Form.Item
            label="End after"
            name="endTimeAfter"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : undefined,
            })}
          >
            <DatePicker
              showTime
              defaultValue={dayjs().startOf('day').subtract(1, 'day')}
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ backgroundColor: 'brown' }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
