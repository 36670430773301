import { useLogin } from '@refinedev/core';
import { useEffect, useRef, useState } from 'react';
import { CredentialResponse } from '../interfaces/google';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  Row,
  Col,
  Layout as AntdLayout,
  Card,
  Form,
  Input,
  Button,
} from 'antd';
import QRCode from 'react-qr-code';
import { NumberOutlined } from '@ant-design/icons';

import { authProvider } from '../authProvider';

// Todo: Update your Google Client ID here
const GOOGLE_CLIENT_ID =
  '519115795896-b3m8ngfj9hdkd3htb1dhei5i3qsjuqd4.apps.googleusercontent.com';

export const Login: React.FC = () => {
  const { mutate: login } = useLogin<CredentialResponse>();

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isGa, setIsGa] = useState<boolean>(false);
  const [gaKey, setGaKey] = useState<any>({});
  const [otp, setOtp] = useState<number>(0);
  const [dataIdentity, setDataIdentity] = useState<CredentialResponse>();

  const GoogleButton = (): JSX.Element => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (typeof window === 'undefined' || !window.google || !divRef.current) {
        return;
      }

      try {
        window.google.accounts.id.initialize({
          ux_mode: 'popup',
          client_id: GOOGLE_CLIENT_ID,
          callback: async (res: CredentialResponse) => {
            if (res.credential) {
              setIsLogin(true);
              setDataIdentity(res);
              const decodedToken: JwtPayload & { email: string } = jwtDecode(
                res.credential,
              );
              try {
                const checkGa = await authProvider.checkGa({
                  email: decodedToken.email as string,
                });
                if (checkGa) {
                  setIsGa(true);
                  setGaKey(checkGa);
                }
              } catch (error) {
                console.log(error);
              }
            }
          },
        });
        window.google.accounts.id.renderButton(divRef.current, {
          theme: 'filled_blue',
          size: 'medium',
          type: 'standard',
        });
      } catch (error) {
        console.log(error);
      }
    }, []);

    return <div ref={divRef} />;
  };

  const [form] = Form.useForm();
  const submitOtp = async () => {
    if (isLogin) {
      try {
        const decodedToken: JwtPayload & { email: string } = jwtDecode(
          dataIdentity?.credential as string,
        );
        const checkOTP: any = await authProvider.login({
          email: decodedToken.email,
          token: dataIdentity?.credential,
          otp,
        });
        console.log('Accesstoken', checkOTP);

        const dataAuth = {
          ...dataIdentity,
          accessToken: checkOTP?.accessToken as string,
        };

        login(dataAuth);
      } catch (error) {
        alert('Login failed');
      }
    } else {
      alert('Wrong OTP. Please try again');
    }
  };

  const gaRender = () => {
    return (
      <>
        {isGa === true && (
          <>
            <div
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
              }}
            >
              <label style={{ fontSize: 24, fontWeight: 'bold' }}>
                Google Authentication Code
              </label>
            </div>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={gaKey.otpauthUrl}
              viewBox={`0 0 256 256`}
            />
          </>
        )}
        <div
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
          }}
        >
          <Form form={form} style={{ maxWidth: 800 }}>
            <Form.Item
              label=""
              style={{ marginBottom: 8, marginTop: 10 }}
              name="layout"
            >
              <label style={{ fontSize: 16 }}>
                Please input OTP of Google Authentication:{' '}
              </label>
            </Form.Item>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Code is required',
                },
              ]}
            >
              <Input
                placeholder="Input OTP"
                type="password"
                onChange={(event) => setOtp(Number(event.target.value))}
                prefix={<NumberOutlined style={{ color: '#00000040' }} />}
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 10 }}>
              <Button type="primary" onClick={submitOtp} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  return (
    <AntdLayout
      style={{
        background: `radial-gradient(50% 50% at 50% 50%, rgb(245 233 187) 0%, #6cdcbc 100%)`,
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '28px',
            }}
          >
            <img src="./imota-small-icon.svg" alt="Imota" width={300} />
          </div>

          <Card
            style={{
              maxWidth: '400px',
              margin: 'auto',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!isLogin ? (
              <>
                <GoogleButton />
              </>
            ) : (
              gaRender()
            )}
          </Card>
        </Col>
      </Row>
    </AntdLayout>
  );
};
