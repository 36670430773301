import { useForm } from '@refinedev/antd';
import { BaseKey } from '@refinedev/core';
import {
  Checkbox,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  ModalProps,
  Spin,
} from 'antd';
import dayjs from 'dayjs';

export const FootballFestMatchEditModal = ({
  recordId,
  onClose,
  ...props
}: ModalProps & {
  recordId: BaseKey;
  onClose: () => void;
}) => {
  const { formProps, form, formLoading } = useForm({
    action: 'edit',
    resource: 'football-fest/match',
    id: recordId,
    onMutationSuccess: () => {
      onClose();
    },
    successNotification: (data) => {
      return {
        message: `Updated match ${data?.data.id}`,
        type: 'success',
      };
    },
  });

  if (formLoading) {
    return (
      <Modal
        {...props}
        onOk={() => form.submit()}
        okText="Save"
        onCancel={onClose}
      >
        <Spin spinning />
      </Modal>
    );
  }

  return (
    <Modal
      {...props}
      onOk={() => form.submit()}
      okText="Save"
      onCancel={onClose}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Can match have draw result?"
          valuePropName="checked"
          name={['canResultBeDraw']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item label="Display priority" name={['displayPriority']}>
          <InputNumber min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label="Is active"
          name={['isActive']}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Start time"
          name={['startTime']}
          rules={[{ required: true }]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker showTime />
        </Form.Item>
        <Form.Item
          label="End time"
          name={['endTime']}
          rules={[{ required: true }]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker showTime />
        </Form.Item>
      </Form>
    </Modal>
  );
};
